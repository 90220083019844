import { Button, FormHelperText, Modal, ModalDialog, Typography } from '@mui/joy'
import { LogoBlackIcon } from '../../icons/LogoIcon'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import styles from './OnboardingPage.module.sass'
import { FormButtonContextProvider } from '../../ui/FormButtonContext'
import _ from 'lodash'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Tour, TourProps, TourStepProps } from 'antd'
import { CreateProfileContext, getDefaultPicture, NavigationTile } from '../CreateProfile/CreateProfilePage'
import { useCreateProfileState } from '../CreateProfile/useCreateProfileState'
import { ProfileBase, ProfileFinancialPeriod, ProfileVariant } from '../../models/Profile'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { ProfilePageSlider } from './ProfilePageSlider'
import { passOnboarding } from '../../clients/UserClient'
import { useAppMounting } from '../../ui/AppState'

function useLatestRef<T>(value: T) {
  const ref = useRef(value)
  useLayoutEffect(() => {
    ref.current = value
  })
  return ref
}

export const makeRenderPanel = (props?: {
  setCurrent?: (value: React.SetStateAction<number>) => void
  hasStepIndicator?: boolean
}):
  | ((step: TourStepProps & { hasButton?: boolean; buttonLabel?: string }, current: number) => React.ReactNode)
  | undefined => {
  return ({ title, description, onNext, total, current, hasButton = true, buttonLabel = 'Далее' }) => {
    return (
      <div className={styles.renderPanel}>
        <Typography level="title-md">{title}</Typography>
        <Typography level="body-sm">{description}</Typography>
        <div className={styles.buttonWrapper}>
          {hasButton && (
            <>
              <span>
                {props?.hasStepIndicator && (
                  <>
                    {(current ?? 0) + 1}/{total}
                  </>
                )}
              </span>
              <Button
                size="sm"
                variant="soft"
                color="primary"
                onClick={() => {
                  onNext?.()
                  if (props?.setCurrent) {
                    props.setCurrent(prev => prev + 1)
                  }
                }}
              >
                {buttonLabel}
              </Button>
            </>
          )}
        </div>
      </div>
    )
  }
}

type Steps = keyof ProfileBase

interface TourFormFieldProps {
  setRef: any
  children: React.ReactNode
  onChange: any
  onActive: any
  isActive: boolean
}
export const TourFormField = ({ children, setRef, isActive, onActive }: TourFormFieldProps) => {
  const onActiveRef = useLatestRef(onActive)

  useEffect(() => {
    if (!isActive) {
      return
    }

    onActiveRef.current()
  }, [isActive, onActiveRef])
  return <div ref={setRef}>{children}</div>
}

const ProfileFormStep = ({ onNext }: { onNext: () => void }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  const renderPanel = makeRenderPanel()

  const steps: TourProps['steps'] = [
    {
      title: 'Профиль',
      description: `Давайте для примера создадим резюме и найдем себе проект.`,
      target: ref.current,
      renderPanel,
      hasButton: false,
    } as any,
  ]

  const [modalOpen, setModalOpen] = useState(true)
  const reloadApp = useAppMounting()

  return (
    <PageLayout className={styles.firstScreen} title="Создание нового профиля">
      <Modal open={modalOpen}>
        <ModalDialog variant="outlined" role="alertdialog">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Typography level="h4">Короткое обучение</Typography>
            <Typography textColor="text.tertiary">
              Мы нескучно покажем Вам основные функции приложения, это займет буквально 3 минуты.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                color="success"
                variant="soft"
                onClick={() => {
                  setModalOpen(false)
                  setOpen(true)
                }}
              >
                Далее
              </Button>
              <Button
                onClick={() => {
                  passOnboarding('Canceled').catch(_.noop).finally(reloadApp)
                }}
                color="neutral"
                variant="plain"
              >
                Я сам
              </Button>
            </div>
          </div>
        </ModalDialog>
      </Modal>
      <div className={styles.startScreenWrapper}>
        <div className={styles.logoWrapper}>
          <LogoBlackIcon style={{ width: 54, height: 54 }} />
          <Typography level="h3">Relevants</Typography>
        </div>
        <Tour animated arrow open={open} steps={steps} gap={{ offset: 12, radius: 4 }} />
        <div>
          <Typography level="h4">Выберите тип профиля</Typography>
          <FormHelperText>В дальнейшем вы сможете создавать другие профили</FormHelperText>
        </div>
        <div className={styles.tilesWrapper}>
          <NavigationTile ref={ref} onClick={onNext} title="Я хочу работать" />
          <NavigationTile onClick={() => {}} title="Я хочу нанять" />
        </div>
      </div>
    </PageLayout>
  )
}

const PositionNameStep = ({ onNext }: { onNext: () => void }) => {
  const refs = useRef<Map<Steps, HTMLElement>>(new Map())
  const setRef = (key: Steps) => (el: HTMLElement | null) => {
    if (el) {
      refs.current.set(key, el)
    }
  }
  const getRef = (key: Steps) => refs.current.get(key) ?? null

  const { onChange, profile } = useCreateProfileState({
    setCurrentStep: _.noop,
    isPersistent: false,
    initialProfile: {
      experience: 4,
      financialPeriod: ProfileFinancialPeriod.Month,
      financialMin: 50000,
      financialMax: 150000,
      extraInfo: 'Сотрудничаю по 50% предоплате.',
      location: 'Москва',
      profilePicture: getDefaultPicture(),
    },
  })

  const [current, setCurrent] = useState(0)
  const [isEditPage, setEditPage] = useState(true)

  const renderPanel = makeRenderPanel({ setCurrent, hasStepIndicator: true })

  const tourSteps: TourProps['steps'] = [
    {
      title: 'Профиль',
      description: `Продолжим создание профиля.\
      Для простоты мы покажем лишь основные поля.`,
      renderPanel,
      onNext: () => {
        const positionName = 'Графический дизайнер'
        for (let i = 0; i < positionName.length; i++) {
          setTimeout(() => {
            onChange('position')(positionName.slice(0, i + 1))
          }, 100 * i)
        }
      },
    },
    {
      title: 'Должность',
      description: 'Укажите должность - мы подберем анкеты.',
      renderPanel,
      target: getRef('position'),
      onNext: () => {
        const description =
          'Графический дизайнер с опытом 4 года. Делаю инфографику и дизайн товаров на маркетплейсах. Знаю Figma, Photoshop, Adobe Illustrator.'
        for (let i = 0; i < description.length; i++) {
          setTimeout(() => {
            onChange('description')(description.slice(0, i + 1))
          }, 10 * i)
        }
      },
    },
    {
      title: 'О себе',
      description: 'Расскажите о себе и своем опыте.',
      renderPanel,
      target: getRef('description'),
      onNext: () => {
        const description = 'Ищу проекты по графическому дизайну.'
        for (let i = 0; i < description.length; i++) {
          setTimeout(() => {
            onChange('lookingForDescription')(description.slice(0, i + 1))
          }, 10 * i)
        }
      },
    },
    {
      title: 'Что ищете',
      description: 'Расскажите, что вы ищете для лучших результатов.',
      renderPanel,
      target: getRef('lookingForDescription'),
    },
    {
      title: 'Финансы',
      description: 'Расскажите о своих финансовых требованиях или любые другие детали.',
      renderPanel,
      target: getRef('financialPeriod'),
      onNext: () => {
        setEditPage(false)
      },
    },
    {
      title: 'Отлично',
      description: 'Мы заполнили профиль, давайте его опубликуем и перейдем к поиску.',
      renderPanel,
      onNext,
      buttonLabel: 'Опубликовать',
    } as any,
  ]

  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    })
  }, [setOpen])

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = 'hidden'
    })

    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [])

  return (
    <>
      <div className={styles.overlay} />
      <PageLayout className={styles.firstScreen} title="Создание нового профиля">
        <div className={styles.startScreenWrapper}>
          {isOpen && (
            <Tour
              current={current}
              animated
              arrow
              open
              steps={tourSteps}
              gap={{ offset: 12, radius: 4 }}
              rootClassName={styles.tourWrapper}
              closable={false}
            />
          )}
          <div>
            <FormButtonContextProvider removeHandler={_.noop} resetToDefault={_.noop}>
              <CreateProfileContext.Provider
                value={{
                  back: _.noop,
                  goToStep: _.noop,
                  next: _.noop,
                  onChange,
                  onClose: _.noop,
                  resetProfile: _.noop,
                  state: profile,
                  setRef,
                  isEditPage,
                  isOnboardingPage: true,
                }}
              >
                <ProfileDetails
                  editable={isEditPage}
                  profile={
                    {
                      ...profile,
                      personName: 'Валерия',
                      birthYear: 2005,
                      hasLike: false,
                      id: 0,
                      variant: ProfileVariant.Resume,
                    } as any
                  }
                  onChange={onChange}
                />
              </CreateProfileContext.Provider>
            </FormButtonContextProvider>
          </div>
          <Button fullWidth size="lg">
            {isEditPage ? 'Сохранить' : 'Опубликовать'}
          </Button>
        </div>
      </PageLayout>
    </>
  )
}

export const OnboardingPage = () => {
  const [step, setStep] = useState(0)

  if (step === 0) {
    return <ProfileFormStep onNext={() => setStep(1)} />
  } else if (step === 1) {
    return <PositionNameStep onNext={() => setStep(2)} />
  } else if (step === 2) {
    return <ProfilePageSlider />
  }

  return null
}
