import { UserId } from './User'

export interface Profile {
  id: number
  variant: ProfileVariant
  position: string
  location: string
  description?: string
  lookingForDescription?: string
  experience: number
  workType?: ProfileWorkType[]
  financialMin?: number
  financialMax?: number
  financialPeriod?: ProfileFinancialPeriod
  extraInfo?: string
  profilePicture?: string
  logToken?: string
}

export type ProfileBase = Partial<Profile>

export enum ProfileWorkType {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum ProfileFinancialPeriod {
  Hour = 'Hour',
  Month = 'Month',
  Project = 'Project',
}

export enum ProfileVariant {
  Resume = 'Resume',
  Job = 'Job',
}

export interface ProfileCity {
  cityName: string
  districtName: string
}

export const profileFinancialPeriodLabels: Record<ProfileFinancialPeriod, string> = {
  [ProfileFinancialPeriod.Hour]: 'Почасовая оплата',
  [ProfileFinancialPeriod.Month]: 'Фиксированная сумма ₽/месяц',
  [ProfileFinancialPeriod.Project]: 'Фиксированная сумма ₽/проект',
}

export const profileFinancialPeriodLabelsCompact: Record<ProfileFinancialPeriod, string> = {
  [ProfileFinancialPeriod.Hour]: 'Ставка в час',
  [ProfileFinancialPeriod.Month]: 'Ставка в месяц',
  [ProfileFinancialPeriod.Project]: 'За проект',
}

export type ProfilePreviewDetails = ProfileQueueItem

export interface ProfileCompact {
  id: number
  variant: ProfileVariant
  position: string
  location?: string
  experience: number
  lookingForDescription: string
  description: string
  extraInfo?: string
  workType?: ProfileWorkType[]
  financialMin?: number
  financialMax?: number
  financialPeriod?: ProfileFinancialPeriod
  profilePicture?: string
  isArchived: boolean
  archivedAt?: string
  createdAt: string
  incomingLikesCount: number
  isParsed: boolean
}

export interface ProfileDayStats {
  date: Date
  likesCount: number
  skipsCount: number
}

export type ProfileQueueItem = Omit<
  ProfileCompact,
  'incomingLikesCount' | 'createdAt' | 'archivedAt' | 'isArchived' | 'category'
> & {
  personName: string
  birthYear: number | -1
  hasLike: boolean
  userId?: number
  telegramId?: number
}

export interface ProfileExplicit extends ProfileQueueItem {
  ip: string
  isSuspended: boolean
  suspendedAt?: boolean
  suspensionReason?: string
  isSuspicious: boolean
  isArchived: boolean
  archivedAt?: string
  isVerified: boolean
  isInSearchIndex: boolean
  createdAt: string
  categoryId?: number
  positionNameId?: number
}

export enum ReportReason {
  InappropriateContent = 'InappropriateContent',
  FakeInfo = 'FakeInfo',
  Scam = 'Scam',
  IndecentBehaviour = 'IndecentBehaviour',
  Other = 'Other',
}

export interface ProfileCategory {
  id: string
  category: string
  items: {
    id: string
    name: string
  }[]
}

export interface ProfileCreationLog {
  id: number
  key: string
  variant: ProfileVariant
  step: number
  userId: UserId
  createdAt: string
}
