import { useEffect, useState } from 'react'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { ProfileContext } from './ProfilePage'
import { ProfileQueueItem } from '../../models/Profile'
import { getProfileMatches } from '../../clients/ProfileClient'
import { Avatar, LinearProgress } from '@mui/joy'
import styles from './ProfileMatchesPage.module.sass'
import { Link, useNavigate } from 'react-router-dom'
import config from '../../config'
import { birthYearToAge, plural } from '../../tools/StringUtil'

export const ProfileMatchesPage = () => {
  const [matches, setMatches] = useState<ProfileQueueItem[] | null>()

  const { profile } = useSafeContext(ProfileContext)

  const navigate = useNavigate()

  useEffect(() => {
    getProfileMatches(profile.id).then(({ data }) => setMatches(data))
  }, [profile])

  return (
    <PageLayout topMenu bottomMenu title={`${profile.position ?? 'Загрузка профиля...'} - Взаимные лайки`}>
      <TopBackLink to={'/profile/' + profile.id} />
      <div className={styles.listWrapper}>
        {matches ? (
          <>
            {!matches.length ? (
              <div className={styles.tile}>
                {profile.isArchived ? (
                  <>Данный профиль не имел взаимных лайков.</>
                ) : (
                  <>
                    Пока нет взаимных лайков. <Link to={`/profile/${profile.id}/search`}>Подборка</Link>
                  </>
                )}
              </div>
            ) : (
              <>
                <span className={styles.listTitle}>Взаимные лайки</span>
                {matches.map(p => (
                  <div
                    className={styles.tile}
                    key={p.id}
                    onClick={() => navigate(`/profile/${profile.id}/view/${p.id}`)}
                  >
                    <div className={styles.avatarCol}>
                      <Avatar size="md" src={config.API_URL + '/' + p.profilePicture} />
                    </div>
                    <div className={styles.detailsCol}>
                      <div className={styles.infoWrapper}>
                        <span className={styles.label}>{p.position}</span>
                        <span className={styles.userInfo}>
                          {p.personName}
                          {!p.isParsed && <>, {birthYearToAge(p.birthYear)} </>}
                          {!p.isParsed && plural(birthYearToAge(p.birthYear) ?? 0, ['год', 'года', 'лет'])}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <LinearProgress />
        )}
      </div>
    </PageLayout>
  )
}
