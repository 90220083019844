import { Button, Skeleton } from '@mui/joy'
import { LikeButtonIcon } from '../../icons/LikeButtonIcon'
import { PersonButtonIcon } from '../../icons/PersonButtonIcon'
import { SwipeDownIcon } from '../../icons/SwipeDownIcon'
import { ProfileQueueItem, ProfileVariant } from '../../models/Profile'
import { Chip } from '@mui/joy'
import { UserDetails } from '../ProfileDetails/UserDetails'
import { Textarea } from '../Textarea'
import { StarEmptyIcon } from '../../icons/StartEmptyIcon'
import { StarFilledIcon } from '../../icons/StarFilledIcon'
import { QueueOverIcon } from '../../icons/QueueOverIcon'
import { SuccessIcon } from '../../icons/SuccessIcon'
import { sendFeedback } from '../../clients/QueueClient'
import { useSafeContext } from '../hooks/useSafeContext'
import { ProfileContext } from '../../pages/ProfilePage/ProfilePage'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import styles from './ProfilesStackCards.module.sass'
import similarity from 'similarity'
import { profileToString } from '../../tools/StringUtil'
import { Link } from 'react-router-dom'

export const ResumeProfileVariant = ({
  profile,
  openProfile,
  onClick,
}: {
  profile: ProfileQueueItem
  openProfile: () => void
  onClick: () => void
}) => {
  const { profile: ownProfile } = useSafeContext(ProfileContext)

  return (
    <>
      <div className={styles.cardContent} onClick={onClick}>
        <UserDetails
          birthYear={profile.birthYear}
          personName={profile.personName}
          financialMax={profile.financialMax}
          financialMin={profile.financialMin}
          location={profile.location}
          financialPeriod={profile.financialPeriod}
          profilePicture={profile.profilePicture}
          title={profile.position}
          workType={profile.workType}
          match={+(similarity(profileToString(profile), profileToString(ownProfile)) * 100).toFixed(0)}
          hasLike={profile.hasLike}
          experience={profile.experience}
          variant={profile.variant}
          isParsed={profile.isParsed}
        />
        <div className={styles.cardRow}>
          <span className={styles.cardLabel}>О себе</span>
          <div className={styles.cardRowContent}>{profile.description}</div>
        </div>
        <div className={styles.cardRow}>
          <span className={styles.cardLabel}>Что ищу</span>
          <div className={styles.cardRowContent}>{profile.lookingForDescription}</div>
        </div>
      </div>
      <div className={styles.fade}>
        <Chip color="primary" variant="soft" onClick={openProfile}>
          Показать всё
        </Chip>
      </div>
    </>
  )
}

export const JobProfileVariant = ({
  profile,
  openProfile,
  onClick,
}: {
  profile: ProfileQueueItem
  openProfile: () => void
  onClick: () => void
}) => {
  const { profile: ownProfile } = useSafeContext(ProfileContext)

  return (
    <>
      <div className={styles.cardContent} onClick={onClick}>
        <UserDetails
          birthYear={profile.birthYear}
          personName={profile.personName}
          financialMax={profile.financialMax}
          financialMin={profile.financialMin}
          location={profile.location}
          financialPeriod={profile.financialPeriod}
          profilePicture={profile.profilePicture}
          title={profile.position}
          workType={profile.workType}
          match={+(similarity(profileToString(profile), profileToString(ownProfile)) * 100).toFixed(0)}
          hasLike={profile.hasLike}
          experience={profile.experience}
          variant={profile.variant}
          isParsed={profile.isParsed}
        />
        <div className={styles.cardRow}>
          <span className={styles.cardLabel}>Описание</span>
          <div className={styles.cardRowContent}>{profile.description}</div>
        </div>
        <div className={styles.cardRow}>
          <span className={styles.cardLabel}>Кого ищет</span>
          <div className={styles.cardRowContent}>{profile.lookingForDescription}</div>
        </div>
      </div>
      <div className={styles.fade}>
        <Chip color="primary" variant="soft" onClick={openProfile}>
          Показать всё
        </Chip>
      </div>
    </>
  )
}

const ProfilePreviewSkeleton = () => {
  return <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
}

export const ProfileCard = ({
  profile,
  onSwipedUp,
  openProfile,
  onLike,
  isOverlayOpen,
}: {
  profile: ProfileQueueItem
  onSwipedUp: (isLike?: boolean) => void
  openProfile: () => void
  onLike: (profileId: number) => void
  isOverlayOpen: boolean
}) => {
  const [clickCounter, setClickCounter] = useState(0)

  useEffect(() => {
    if (clickCounter) {
      const timeout = setTimeout(() => {
        setClickCounter(0)
        if (clickCounter === 2) {
          onLike(profile.id)
        }
      }, 250)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [clickCounter, onLike, profile.id])

  return (
    <>
      {isOverlayOpen && (
        <div className={styles.likeOverlay}>
          <LikeButtonIcon />
        </div>
      )}
      {profile.variant === ProfileVariant.Resume ? (
        <ResumeProfileVariant
          profile={profile}
          onClick={() => setClickCounter(prev => prev + 1)}
          openProfile={openProfile}
        />
      ) : (
        <JobProfileVariant
          profile={profile}
          onClick={() => setClickCounter(prev => prev + 1)}
          openProfile={openProfile}
        />
      )}
      <div className={styles.cardControls}>
        <div onClick={openProfile}>
          <PersonButtonIcon />
        </div>
        <div className={styles.skipCol} onClick={() => onSwipedUp()}>
          <SwipeDownIcon color="#131318" />
          <span>Следующий</span>
        </div>
        <div>
          <LikeButtonIcon onClick={() => onLike(profile.id)} />
        </div>
      </div>
    </>
  )
}

export const RateCard = ({ swipeUp }: { swipeUp: () => void }) => {
  const [rating, setRating] = useState<number>()
  const [comment, setComment] = useState<string>('')
  const [requestState, setRequestState] = useState<'processing' | 'success'>()

  const { profile } = useSafeContext(ProfileContext)

  const ratingLabels = [
    'Совсем нерелевантные',
    'Частично релевантные',
    'Могло быть и лучше',
    'Почти отлично',
    'Отличные результаты!',
  ]

  const isInvalid = comment.trim().length > 100

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (requestState === 'success') {
      timeout = setTimeout(() => swipeUp(), 500)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [requestState, swipeUp])

  return (
    <div className={styles.rateCardWrapper}>
      {!requestState ? (
        <>
          <div className={styles.rateContentWrapper}>
            <div className={styles.inner}>
              <h1 className={styles.rateTitle}>Насколько вы довольны подборкой?</h1>
              <span className={styles.rateText}>Мы стремимся предоставить вам только подходящие профили</span>
              <div className={styles.stars}>
                {_.range(0, 5).map(s => {
                  if (rating !== undefined && rating >= s) {
                    return <StarFilledIcon key={s} onClick={() => setRating(s)} />
                  } else {
                    return <StarEmptyIcon key={s} onClick={() => setRating(s)} />
                  }
                })}
              </div>
              {rating !== undefined && <span className={styles.starsLabel}>{ratingLabels[rating]}</span>}
            </div>
            <Textarea
              value={comment}
              onChange={e => setComment(e.target.value)}
              helperText="До 100 символов"
              minRows={3}
              maxRows={3}
              label="У вас есть, что сказать нам?"
              placeholder="Расскажите нам, это поможет улучшить нашу систему подбора"
              error={isInvalid}
            />
          </div>
          <div className={styles.buttons}>
            <Button loading={requestState === 'processing'} variant="plain" color="neutral" onClick={() => swipeUp()}>
              Оценю позже
            </Button>
            <Button
              disabled={rating === undefined || isInvalid}
              onClick={() => {
                if (rating === undefined) {
                  return
                }

                setRequestState('processing')
                sendFeedback({
                  profileId: profile.id,
                  rating: rating + 1,
                  comment,
                }).finally(() => {
                  setRequestState('success')
                })
              }}
              loading={requestState === 'processing'}
              variant="soft"
            >
              Отправить отзыв
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.successWrapper}>
          <SuccessIcon />
          <h1 className={styles.rateTitle}>Спасибо за оценку</h1>
          <span className={styles.rateText}>Отзыв отправлен</span>
        </div>
      )}
    </div>
  )
}

export const NoResultsCard = ({ noResults, noLikes }: { noResults?: boolean; noLikes?: boolean }) => {
  const { profile } = useSafeContext(ProfileContext)

  return (
    <>
      {!noResults ? (
        <ProfilePreviewSkeleton />
      ) : (
        <div className={styles.noResultsWrapper}>
          <QueueOverIcon />
          <span>
            {!noLikes ? (
              <>Нет подходящих анкет. Мы оповестим, как только появятся новые результаты</>
            ) : (
              <>
                Нет новых лайков. <Link to={`/profile/${profile.id}/search`}>Подборка</Link>
              </>
            )}
          </span>
        </div>
      )}
    </>
  )
}
