import * as BaseToast from '@radix-ui/react-toast'
import { createContext, useCallback, useState } from 'react'
import { useSafeContext } from '../hooks/useSafeContext'
import styles from './Toast.module.sass'
import _ from 'lodash'

interface ToastContextState {
  addToast: (toast: ToastType) => void
}

const ToastContext = createContext<ToastContextState | null>(null)

interface ToastType {
  title?: string
  description?: string
  id?: string
  variant?: 'default' | 'error' | 'message'
  duration?: number
}

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastType[]>([])

  const addToast = useCallback(
    (toast: ToastType) => {
      setToasts(prev => [...prev, { ...toast, id: _.uniqueId() }])
    },
    [setToasts],
  )

  return (
    <BaseToast.Provider>
      <ToastContext.Provider value={{ addToast }}>{children}</ToastContext.Provider>
      {toasts.map(({ title, description, id, duration, variant = 'default' }) => (
        <BaseToast.Root
          duration={duration}
          key={id}
          className={styles.toastWrapper + ` ${styles['variant-' + variant]}`}
          onOpenChange={open => {
            if (!open) {
              setToasts(prev => prev.filter(t => t.id !== id))
            }
          }}
        >
          {title && <BaseToast.Title className={styles.title}>{title}</BaseToast.Title>}
          {description && <BaseToast.Description className={styles.description}>{description}</BaseToast.Description>}
        </BaseToast.Root>
      ))}
      <BaseToast.Viewport className={styles.toastViewport} />
    </BaseToast.Provider>
  )
}

export const useToast = () => {
  const { addToast } = useSafeContext(ToastContext)

  const showToast = useCallback((toast: ToastType) => addToast(toast), [addToast])

  return { showToast }
}
