import { ProfileContextProvider } from '../ProfilePage/ProfilePage'
import { BottomNavigationItem } from '../../ui/BottomNavigation/BottomNavigationItem'
import { HouseIcon } from '../../icons/HouseIcon'
import _ from 'lodash'
import { UsersIcon } from '../../icons/UsersIcon'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { LinearProgress } from '@mui/joy'
import { BottomNavigation } from '../../ui/BottomNavigation/BottomNavigation'
import { ProfilesMenu } from '../../ui/ProfilesMenu/ProfilesMenu'
import styles from './ProfilePageSlider.module.sass'
import {
  ProfileCompact,
  ProfileFinancialPeriod,
  ProfileQueueItem,
  ProfileVariant,
  ProfileWorkType,
} from '../../models/Profile'
import { LikeIcon } from '../../icons/LikeIcon'
import { ProfilesStack } from '../../ui/ProfilesStack/ProfilesStack'
import { getDefaultPicture } from '../CreateProfile/CreateProfilePage'
import { useCallback, useEffect, useState } from 'react'
import { Tour, TourProps } from 'antd'
import { makeRenderPanel } from './OnboardingPage'
import { StackOnboarding } from '../../ui/ProfilesStack/StackOnboarding'
import { passOnboarding } from '../../clients/UserClient'
import { useAppMounting } from '../../ui/AppState'

const tabCn = (isActive: boolean) => styles.navigationItemContent + (isActive ? ` ${styles.active}` : ``)

export const ProfilePageSlider = () => {
  const [profiles, setProfiles] = useState<ProfileQueueItem[]>(['noCardsLeft'] as any)

  useEffect(() => {
    setTimeout(() => {
      setProfiles([
        {
          birthYear: 2000,
          description:
            'Мы - одна из ведущих компаний в области брендинга, графического дизайна и digital коммуникаций приглашает дизайнера по шрифтам и логотипам, на постоянную работу, в офис.',
          experience: 1,
          hasLike: true,
          id: -1 * Number(_.uniqueId()),
          userId: -1,
          lookingForDescription: `Идеальное знание графического пакета Adobe, Высокий уровень визуализации проектов.`,
          extraInfo: 'Полная занятость, график с 10 до 19; Новый светлый офис в удобной локации;',
          personName: 'Юлия',
          position: 'Граф. дизайнер',
          variant: ProfileVariant.Job,
          profilePicture: getDefaultPicture(),
          workType: [ProfileWorkType.FullTime, ProfileWorkType.PartTime],
          financialMin: 75000,
          financialMax: 120000,
          financialPeriod: ProfileFinancialPeriod.Month,
          location: 'Москва',
          isParsed: false,
        },
        {
          birthYear: 2000,
          description:
            'Мы - специализированное агентство интернет-рекламы. Мы специализируемся на разработке и реализации рекламных стратегий в digital для крупных брендов и топовых рекламодателей на рынке, с использованием закупки медийной, аудио и видеорекламы, в том числе с использованием influence-маркетинга, спец. проектов и интеграций с крупными блогерами.',
          experience: 1,
          hasLike: true,
          telegramId: 123,
          id: -1 * Number(_.uniqueId()),
          userId: -1,
          lookingForDescription: `Дизайн-поддержка. Создание баннеров для рекламных кампаний (внутренние и клиентские проекты). Оформление и верстка страниц на платформе Tilda.`,
          extraInfo: 'Удалённая работа из любой точки мира;',
          personName: 'Елизавета',
          position: 'Дизайнер',
          variant: ProfileVariant.Job,
          profilePicture: getDefaultPicture(),
          workType: [ProfileWorkType.FullTime, ProfileWorkType.PartTime],
          financialMin: 100000,
          financialMax: 136000,
          financialPeriod: ProfileFinancialPeriod.Month,
          isParsed: false,
        },
        {
          birthYear: 1970,
          description:
            '16 лет занимаемся реализацией всего спектра задач, связанных с IT и бизнесом в Интернете. Мы помогаем бизнесу быть эффективнее и современнее, используя последние достижения в сфере высоких технологий.',
          experience: 5,
          hasLike: true,
          telegramId: 123,
          id: -1 * Number(_.uniqueId()),
          userId: -1,
          lookingForDescription: `Опыт в управлении командой дизайнеров. Опыт разработки концепции дизайна, включая концепцию решения. Опыт создания растровых и векторных иллюстраций.`,
          extraInfo: 'Официальное оформление с первого дня работы.',
          personName: 'Николай',
          position: 'Главный UX/UI дизайнер',
          variant: ProfileVariant.Job,
          profilePicture: getDefaultPicture(),
          workType: [ProfileWorkType.FullTime],
          financialMin: 300000,
          financialMax: 560000,
          financialPeriod: ProfileFinancialPeriod.Month,
          isParsed: false,
        },
      ])
    }, 1200)
  }, [setProfiles])

  const profile: ProfileCompact = {
    createdAt: '',
    description: '',
    experience: 1,
    id: -1,
    incomingLikesCount: 0,
    isArchived: false,
    lookingForDescription: '',
    position: 'Графический дизайнер',
    variant: ProfileVariant.Resume,
    profilePicture: getDefaultPicture(),
    isParsed: false,
  }
  const tab = 'profiles' as any

  const [isTourOpen, setTourOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setTourOpen(true)
    }, 1200)
  }, [setTourOpen])

  const [current, setCurrent] = useState(0)
  const [isOnboardingOpen, setOnboardingOpen] = useState(false)

  const renderPanel = makeRenderPanel()

  const reloadApp = useAppMounting()

  const tourSteps: TourProps['steps'] = [
    {
      title: 'Подборка',
      description: `Подходящие анкеты будут в разделе "Подборка". Мы напомним в Telegram, если подберем новые результаты.`,
      renderPanel,
      onNext: () => setCurrent(prev => prev + 1),
    },
    {
      title: 'Подборка',
      description: `Попробуйте сами пролистать результаты и лайкнуть кого-нибудь.`,
      renderPanel,
      onNext: () => {
        setCurrent(prev => prev + 1)
        setOnboardingOpen(true)
        setTourOpen(false)
      },
    },
    {
      title: 'Ура!',
      description: `Мы рассказали вам об основных функциях. Теперь вы можете создать свой профиль, удачи!`,
      renderPanel,
      onNext: () => {
        passOnboarding('Done')
          .catch(() => console.log('Something went wrong'))
          .finally(reloadApp)
      },
      buttonLabel: 'Завершить',
    } as any,
  ]

  const onFinish = useCallback(() => {
    setTourOpen(true)
  }, [setTourOpen])

  return (
    <>
      {isOnboardingOpen && (
        <StackOnboarding
          onClose={() => {
            setOnboardingOpen(false)
          }}
          isOnboarding
        />
      )}
      {profile ? (
        <ProfileContextProvider refreshProfile={_.noop} incomingLikes={[]} profile={profile} isOnboarding>
          {isTourOpen && (
            <Tour
              current={current}
              animated
              arrow
              open
              steps={tourSteps}
              gap={{ offset: 12, radius: 4 }}
              rootClassName={styles.tourWrapper}
              closable={false}
            />
          )}
          <PageLayout className={styles.mainWrapper} title={`${profile.position ?? 'Загрузка профиля...'} - Подборка`}>
            <ProfilesStack profiles={profiles} refetch={_.noop} onFinish={onFinish} />
          </PageLayout>
          <BottomNavigation>
            <BottomNavigationItem onClick={_.noop}>
              <div className={tabCn(tab === 'main')}>
                <HouseIcon className={styles.navigationItemIcon} />
                <span>Главная</span>
              </div>
            </BottomNavigationItem>
            <BottomNavigationItem onClick={_.noop}>
              <div className={tabCn(tab === 'profiles')}>
                <UsersIcon className={styles.navigationItemIcon} />
                <span>Подборка</span>
              </div>
            </BottomNavigationItem>
            <BottomNavigationItem onClick={_.noop}>
              <div className={tabCn(tab === 'likes')}>
                <LikeIcon className={styles.navigationItemIcon} />
                <span>Лайки</span>
              </div>
            </BottomNavigationItem>
            {profiles !== null && <ProfilesMenu profiles={[profile]} className={tabCn(false)} />}
          </BottomNavigation>
        </ProfileContextProvider>
      ) : (
        <PageLayout>
          <LinearProgress />
        </PageLayout>
      )}
    </>
  )
}
